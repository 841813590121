import React from 'react';

import Layout from '../components/layout'
import SEO from '../components/seo'
import MyForm from '../components/myform';

const ContactPage = () => (
	<Layout>
		<SEO title="联系我" />
		<MyForm />
	</Layout>
);

export default ContactPage;
