// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from './myform.js''.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React from 'react';
import style from '../styles/myform.module.scss';

export default class MyForm extends React.Component {
	constructor(props) {
		super(props);
		this.submitForm = this.submitForm.bind(this);
		this.state = {
			status: ''
		};
	}

	render() {
		const { status } = this.state;
		return (
			<form
				onSubmit={this.submitForm}
				action='https://formspree.io/moqkelod'
				method='POST'
				className={style.myform}
			>
				
				<input type='email' name='email' placeholder='您的邮箱'/>
				
				<textarea type='textarea' name='message' className={style.textarea} placeholder='请输入信息'/>
				{status === 'SUCCESS' ? <p>谢谢！</p> : <button className={style.submit}>提交</button>}
				{status === 'ERROR' && <p>诶？出错了！</p>}
			</form>
		);
}

	submitForm(ev) {
		ev.preventDefault();
		const form = ev.target;
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			if (xhr.status === 200) {
				form.reset();
				this.setState({ status: 'SUCCESS' });
			} else {
				this.setState({ status: 'ERROR' });
			}
		};
		xhr.send(data);
	}
}